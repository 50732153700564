.item-calciatore-mobile {
    background: #ffffff;
    box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    width: 96%;
}

.swipeable-list-item {
    padding-bottom: 50px;
    margin-top: 0px;
    margin-bottom: -40px;
}

.swipeable-list-item:last-child {
    margin-bottom: 0px;
}

.swipeable-list-item__leading-actions {
    padding-bottom: 50px !important;
    margin-top: 0px;
    margin-bottom: -20px;
}

.swipeable-list-item__trailing-actions {
    padding-bottom: 50px !important;
    margin-top: 0px;
    margin-bottom: -20px;
}


.dot {
    width: 30px;
    height: 30px;
    font-size: 13px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    border-radius: 100%;
}



.color-white {
    color: #ffffff;
}


.basic-swipeable-list__container {
    width: 100%;
    margin: 16px 0;
}



.swipeable-list {
    background-color: rgba(0, 0, 0, 0) !important;
}




.basic-swipeable-list__container {
    width: 100%;
    margin: 16px 0;
    transform: scale(1.062);
}


.swipeable-list__container {
    width: 100%;
    margin: 16px 0;
    transform: scale(1.062);
}

.swipeable-list-item__content {
    transform: translateX(0px);
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
}


