//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.navigation-link-outline, .btn-outline-primary{
	border: 1px solid #2cab66 !important;
}

.alert-dashboard{
	display: flex;
	align-items: center;
	.alert-text{
		width: 96%;
	}
	.btn-outline-primary{
		color: #1c4b44 !important;
		font-size: 1.2rem;
		border-radius: .25em;
		background: transparent;
		padding: 0.25em 0.5em;
	}
	@media screen and (max-width: 768px){
		flex-direction: column;
		align-items: flex-start;
		.alert-text{
			width: 100%;
		}
	}
}

/*.badge{
	font-size: 1.2rem !important;
	@media screen and (max-width: 768px){
		font-size: .93rem !important;
	}
}*/

.bg-mantraWT{
	background-color: #A888FD !important;
}

.mobile-subheader{
	position: fixed;
	width: 100%;
}

.aside{
	max-width: 5.3em;
	.btn-primary .navigation-text, .yourLeague{
		display: none !important;
	}
	&.open{
		max-width: 100%;
		@media screen and (max-width: 480px){
			width: 20rem;
		}
		.btn-primary .navigation-text{
			display: inline !important;
		}
		.yourLeague{
			display: flex !important;
		}
	}
}

.planPro{
	background: linear-gradient(
		60deg,
		rgba(#2cab66, 1) 0%,
		rgba(#64C515, 1) 100%
	);
	color: #fff;
	.list-group-item{
		background: transparent !important;
		color: #fff !important;
	}
}

.btn-white{
	background: #fff;
	color: #2cab66;
	&:hover{
		background: #fff;
		color: #2cab66;
	}
}

.bannerPlayerList{
	@media screen and (max-width: 480px){
		padding-top: 8rem;
	}
}

.bannerAdsMobileFixed{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	padding: 0;
	display: none;
	@media screen and (max-width: 480px){
		display: inline-block;
	}
}

.page-with-banner{
	@media screen and (max-width: 480px){
		padding-bottom: 10em;
	}
}